import { type UserEventUser } from "@/elements/user-event/user/user-event-user";

const USER_EVENT_USER_LOCAL_STORAGE_KEY = "bloom_widget_user_event_user";

export const getLocalStorageUserEventUser = (): UserEventUser | undefined => {
  const user = localStorage.getItem(USER_EVENT_USER_LOCAL_STORAGE_KEY);

  if (user) {
    try {
      return JSON.parse(user) as UserEventUser;
    } catch (_error) {
      console.error("Could not parse user event user.");
    }
  }

  return undefined;
};

export const setLocalStorageUserEventUser = (user: UserEventUser): void => {
  localStorage.setItem(USER_EVENT_USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
};
