import { createUserEventUser } from "@/elements/user-event/user/user-event-user.utilities";
import type {
  UserEvent,
  UserEventAction,
} from "@/elements/user-event/user-event";
import { sendCreateUserEventRequest } from "@/elements/user-event/user-event.api";

export const trackUserEvent = async (
  widgetId: string,
  propertyId: string,
  action: UserEventAction,
) => {
  const userEvent = createUserEvent(widgetId, propertyId, action);

  try {
    await sendCreateUserEventRequest(userEvent);
  } catch (error) {
    console.error(`Could not track user event: ${JSON.stringify(userEvent)}`);
    console.error(error);
  }
};

const createUserEvent = (
  widgetId: string,
  propertyId: string,
  action: UserEventAction,
): UserEvent => ({
  user: createUserEventUser(),
  widgetId,
  propertyId,
  action,
  hostUrl: location.host + location.pathname + location.search,
  userAgent: navigator.userAgent,
  screenSize: {
    width: screen.width,
    height: screen.height,
  },
  datetime: Date.now(),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});
