import { localized, msg } from "@lit/localize";
import { Task } from "@lit/task";
import { createFocusTrap, type FocusTrap } from "focus-trap";
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { createRef, ref } from "lit/directives/ref.js";
import { createElement, X } from "lucide";
import { clearBodyLocks, lock, unlock } from "tua-body-scroll-lock";

import { baseStyles } from "@/elements/assets/css/base";
import { BookingElementController } from "@/elements/booking-element/booking-element.controller";
import { makePortalWebUnitSelectionUrl } from "@/elements/portal-web/portal-web.utilities";
import { WidgetElementsBookingElementDialogEnterSide } from "@/elements/widget/elements/booking-element/dialog/widget-elements-booking-element-dialog";
import { WidgetController } from "@/elements/widget/widget.controller";

@localized()
@customElement("bloom-booking-dialog")
export class BookingDialogElement extends LitElement {
  private widgetController = new WidgetController(this);
  private bookingElementController = new BookingElementController(this);

  @property({ attribute: "widget-id" })
  private widgetId: string;

  private dialogElement = createRef<HTMLDivElement>();

  private focusTrap: FocusTrap | undefined;

  private initializeTask = new Task(this, {
    task: async ([widgetId]) => {
      if (widgetId) {
        this.bookingElementController.resetActivePropertyId();
        await this.widgetController.initialize(widgetId);
      }
    },
    args: () => [this.widgetId],
  });

  private get iframeSrc(): string {
    return makePortalWebUnitSelectionUrl(
      this.widgetId,
      this.bookingElementController.activePropertyId,
    );
  }

  private get closeIcon(): SVGElement {
    const closeIcon = createElement(X);

    closeIcon.setAttribute("height", "20");
    closeIcon.setAttribute("width", "20");

    return closeIcon;
  }

  private get enterSide(): WidgetElementsBookingElementDialogEnterSide {
    return this.widgetController.bookingElement.dialog.enterSide;
  }

  private get dialogPositionClasses(): string {
    return `md:translate-x-0 ${
      this.enterSide === WidgetElementsBookingElementDialogEnterSide.Left
        ? "md:left-3 md:group-aria-hidden:-translate-x-[calc(100%+theme('spacing.3'))]"
        : "md:right-3 md:group-aria-hidden:translate-x-[calc(100%+theme('spacing.3'))]"
    }`;
  }

  private get closeButtonPositionClasses(): string {
    return this.enterSide === WidgetElementsBookingElementDialogEnterSide.Left
      ? "md:left-full md:right-auto md:ml-3"
      : "md:left-auto md:right-full md:mr-3";
  }

  private handleKeydown({ key }: KeyboardEvent) {
    if (key === "Escape") {
      this.bookingElementController.closeDialog();
    }
  }

  private handleDialogOpen() {
    this.focusTrap?.activate();

    lock();

    window.addEventListener("keydown", this.handleKeydown.bind(this));
  }

  private handleDialogClose() {
    this.focusTrap?.deactivate();

    unlock();

    window.removeEventListener("keydown", this.handleKeydown.bind(this));
  }

  private initializeFocusTrap(element: HTMLElement) {
    this.focusTrap = createFocusTrap(element, {
      clickOutsideDeactivates: true,
      preventScroll: true,
      checkCanFocusTrap: () =>
        // We need to wait for the dialog's contents to be tabbable
        new Promise<void>((resolve) => setTimeout(resolve)),
    });
  }

  protected override firstUpdated() {
    if (this.dialogElement.value) {
      this.initializeFocusTrap(this.dialogElement.value);
    }
  }

  protected override render() {
    if (this.bookingElementController.isDialogOpen) {
      this.handleDialogOpen();
    } else {
      this.handleDialogClose();
    }

    return html`<div
      ${ref(this.dialogElement)}
      aria-hidden="${!this.bookingElementController.isDialogOpen}"
      class="${this.bookingElementController.isDialogOpen
        ? ""
        : "transition-[visibility] invisible delay-500"} group fixed inset-0 z-[99999] max-h-[100vh] max-w-[100vw]"
    >
      <div
        class="absolute inset-0 bg-black/40 transition-opacity duration-500 group-aria-hidden:opacity-0"
        aria-hidden="true"
        @click="${() => {
          this.bookingElementController.closeDialog();
        }}"
      ></div>

      ${this.initializeTask.render({
        complete: () =>
          html` <div
            role="dialog"
            aria-modal="true"
            aria-label="${msg("Booking System")}"
            class="${this
              .dialogPositionClasses} absolute bottom-0 h-[90%] w-full translate-y-0 transition-transform duration-500 ease-in-out group-aria-hidden:translate-y-full md:inset-y-3 md:h-auto md:w-1/2 md:group-aria-hidden:translate-y-0 xl:w-1/3"
          >
            <button
              @click="${() => {
                this.bookingElementController.closeDialog();
              }}"
              aria-label="${msg("close")}"
              class="${this
                .closeButtonPositionClasses} absolute bottom-full left-0 right-0 mx-auto mb-3 w-fit rounded-full bg-white p-2 text-zinc-800 shadow-md transition-opacity duration-500 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-white group-aria-hidden:opacity-0 md:bottom-0 md:top-0 md:my-auto md:h-fit"
            >
              ${this.closeIcon}
            </button>

            <iframe
              allow="payment"
              title="${msg("Booking System")}"
              class="h-full w-full rounded-t-lg bg-white shadow-md md:rounded-lg"
              src="${this.iframeSrc}"
            ></iframe>

            <!--
              Note that we need to have tabbable elements before and after the
              iframe to ensure tabbing cycles correctly
            -->
            <div tabindex="0"></div>
          </div>`,
        error: console.error,
      })}
    </div>`;
  }

  public override disconnectedCallback() {
    clearBodyLocks();

    super.disconnectedCallback();
  }

  public static override styles = [baseStyles];
}
